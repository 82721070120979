import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.css";
import ImageCard from "examples/Cards/BlogCards/ImageCard";
import MKTypography from "components/MKTypography";
import { oaxacaImages } from "./config.js";

function OaxacaCarousel() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    slidesToShow: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  return (
    <div className="slider-container">
      <MKTypography variant="h2" textAlign="center" mb="20px">
        {"Classes in Wonderful Places"}
      </MKTypography>
      <Slider asNavFor={nav2} ref={(slider) => (sliderRef1 = slider)} {...settings}>
        {oaxacaImages.map((image, index) => (
          <div key={index}>
            <ImageCard
              image={image}
              title={`Student ${index + 1}`}
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "Schedule Here",
              }}
            />
          </div>
        ))}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider) => (sliderRef2 = slider)}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        {...settings2}
      >
        {oaxacaImages.map((image, index) => (
          <div key={index}>
            <ImageCard
              image={image}
              title={`Student ${index + 1}`}
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "Schedule Here",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OaxacaCarousel;
