/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
//import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import NewReviewCard from "examples/Cards/ReviewCards/NewReviewCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" textAlign="center">
            {"Trusted by "}
          </MKTypography>
          <MKTypography variant="h2" color="info" textGradient ml={1} mb={2} textAlign="center">
            {" our Students"}
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            For us, it&apos;s crucial to highlight the experiences, feedback, and observations from
            our students to provide you with better service. Here, you&apos;ll find what they have
            to say about our study program and team. We&apos;re confident that if you decide to stay
            with us, you&apos;ll see your name in this section in the future:
          </MKTypography>
        </Grid>
        <Grid container justifyContent="center" alignItems="stretch" direction="row" spacing={3}>
          <Grid item xs={12} md={6} lg={4} display="flex">
            <NewReviewCard
              color="pink"
              name="Maggie Aspland"
              reviews={[
                "Kevin has been my Spanish teacher for the last two years both in person and via Zoom. He is a friendly and engaging teacher, making the classes fun and enjoyable. The classes are a combination of conversation and structured learning.",
                "While learning Spanish I was able to see many interesting places that were off the typical tourist path in Oaxaca. I highly recommend the school no matter what level from beginner to advanced as the teaching is one-on-one and tailored to the needs of the individual.",
              ]}
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex">
            <NewReviewCard
              color="info"
              name="Erin Tougher"
              reviews={[
                "Patty is an excellent Spanish language teacher and I can think of nothing better than sitting in a cafe in Oaxaca chatting, drinking coffee and learning Spanish with her. Oaxaca is a beautiful city and to be able to wander through its cafes, be immersed in the culture and learn Spanish at the same time is truly a dream come true.",
                "Patty is so easy to talk to and will meet you at your Spanish language level. Her English is excellent and she is extremely patient so if you're a beginner learner you will  feel very comfortable. I would highly recommend Cafe con Espanol!",
              ]}
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex">
            <NewReviewCard
              color="pink"
              name="Sarah Toby"
              reviews={[
                "Lessons with Patty are always fun and encouraging. In addition to being an excellent teacher, Patty is very warm and kind, and can help you see what you’re doing wrong without destroying your confidence.",
                "She is very flexible in her approach, and can offer as much or as little structure as you want or need. If you need a very structured approach, with an emphasis on grammar, she can provide materials,  exercises and drills. If you want materials to read and then discuss, she can do that. If, on the other hand, you just want to practice talking, Patty is the perfect person to practice with, listening attentively and gently correcting your mistakes.",
              ]}
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
