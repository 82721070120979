/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";

import MKAvatar from "components/MKAvatar";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import ContactUsButtons from "pages/LandingPages/ContactUs/ContactUsButtons";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import profilePicture from "assets/images/bg-principal-rosa.jpg";
import bgImage from "assets/images/logo-who-we-are-only.png";

function ContactUs() {
  return (
    <>
      <MKBox bgColor="#df79a9">
        <DefaultNavbar routes={routes} sticky light />
        <MKBox
          minHeight="24rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.green.main, 0.1),
                rgba(gradients.green.light, 1)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h0"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Schedule is open!
          </MKTypography>
        </MKBox>
      </MKBox>
      <Card>
        <MKBox component="section" pt={6} px={1} mt={6}>
          <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                <MKAvatar src={profilePicture} alt="Burce Mars" size="2xl" shadow="xl" />
              </MKBox>
              <Grid container justifyContent="center" py={6}>
                <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <MKTypography variant="h3">Schedule by Yourself</MKTypography>
                  </MKBox>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={3}>
                    Ready to try out some classes with us? Now, scheduling couldn&apos;t be easier
                    thanks to our different methods of contact! With just a few clicks, you can pick
                    the perfect time for your class without any hassle. Our program is perfect if
                    you want to discover the city, taste delicious coffee and learn Spanish in a
                    natural and fun way. You&apos;ll have a unique experience with a personal tutor
                    who is going to take you to different beautiful cafés and emblematic landmarks
                    of Oaxaca city. So let&apos;s get started on this journey together - schedule
                    your class today and let the adventure begin!
                    <br />
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={3}>
                    If you have any further questions or concerns about starting your booking
                    process, you can contact us through one of the following means
                  </MKTypography>
                  <ContactUsButtons />
                </Grid>
              </Grid>
            </Grid>
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              <Featuring />
            </Card>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
