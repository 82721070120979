/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Testimonials from "pages/Presentation/sections/Testimonials";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-principal-rosa.jpg";

// Carrousel
import OnlineCarousel from "pages/LandingPages/AboutUs/sections/subsections/OnlineCarousel";
import StudentsCarousel from "pages/LandingPages/AboutUs/sections/subsections/StudentsCarousel";
import OaxacaCarousel from "pages/LandingPages/AboutUs/sections/subsections/OaxacaCarousel";

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky light={true} />
      <MKBox
        minHeight="32rem"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <DesignBlocks />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="color"
                color="info"
                icon="coffee"
                title="Class in a Local Cafe"
                description="Experience the heart of Oaxaca's culture firsthand by learning Spanish in a charming local cafe. Engage in language lessons amidst the vibrant atmosphere, savoring the language and flavors of Mexico simultaneously."
                action={{
                  type: "external",
                  route: "/pages/landing-pages/services",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="signpost"
                title="Take a Walking Lesson"
                description="Combine language learning with exploration as we take you to Oaxaca's iconic sites. Our instructors will guide you through immersive language sessions while you visit local treasures, making your learning experience engaging and memorable."
                action={{
                  type: "external",
                  route: "/pages/landing-pages/services",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="forum"
                title="Fun Conversational Lessons"
                description="We offer conversational and enjoyable lessons with a highly professional approach that will keep you engaged in every lesson."
                action={{
                  type: "external",
                  route: "/pages/landing-pages/services",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Testimonials />
        <StudentsCarousel />
        <OnlineCarousel />
        <OaxacaCarousel />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
