// Here I will declare an array of images, one for each folder in /assets/images/
// for example, for "online" folder, I will have an array of images with the following names:
// online_1.png, online_2.png, online_3.png, etc. and so on.

import online_1 from "assets/images/online/online_1.png";
import online_2 from "assets/images/online/online_2.png";
import online_3 from "assets/images/online/online_3.png";

import students_1 from "assets/images/students/students_1.png";
import students_2 from "assets/images/students/students_2.png";
import students_3 from "assets/images/students/students_3.png";
import students_4 from "assets/images/students/students_4.png";
import students_5 from "assets/images/students/students_5.png";
import students_6 from "assets/images/students/students_6.png";
import students_7 from "assets/images/students/students_7.png";
import students_8 from "assets/images/students/students_8.png";
import students_9 from "assets/images/students/students_9.png";
import students_10 from "assets/images/students/students_10.png";
import students_11 from "assets/images/students/students_11.png";
import students_12 from "assets/images/students/students_12.png";
import students_13 from "assets/images/students/students_13.png";

import places_1 from "assets/images/places/places_1.png";
import places_2 from "assets/images/places/places_2.png";
import places_3 from "assets/images/places/places_3.png";
import places_4 from "assets/images/places/places_4.png";
import places_5 from "assets/images/places/places_5.png";
import places_6 from "assets/images/places/places_6.png";

export const onlineImages = [online_1, online_2, online_3];
export const studentsImages = [
  students_1,
  students_2,
  students_3,
  students_4,
  students_5,
  students_6,
  students_7,
  students_8,
  students_9,
  students_10,
  students_11,
  students_12,
  students_13,
];
export const oaxacaImages = [places_1, places_2, places_3, places_4, places_5, places_6];
