/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/patty_avatar.png";
import team2 from "assets/images/kevin_avatar.png";
import team3 from "assets/images/enrique_avatar.JPG";
import team4 from "assets/images/nancy_avatar.JPG";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="info"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Meet the passionate individuals who bring our Oaxaca adventure to life! Our team is
              composed of dedicated tutors, seasoned travelers, and cultural enthusiasts, all united
              by a shared love for language, exploration, and authentic experiences. With diverse
              backgrounds and expertise, our team is committed to providing you with personalized
              guidance, insider knowledge, and unforgettable moments throughout your journey.
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Get to know the faces behind the adventure you are about to live.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Patty"
                position={{ color: "info", label: "Co-founder and Head Teacher" }}
                description="Hi everyone! I'm Patty, and I'm super excited about being in this project. I've had a long career as a teacher and coordinator in both Spanish and English classes for all ages. I'm always keen on sharing moments and learning new things every day. I have a big love for music, fashion, cinema, travel, animals, and food. I have three little dogs who are my motivation every single day. Making music and singing bring me so much joy; actually I’m the singer of my own rock pop band. In this program, I'll help you have a wonderful time in the city while learning Spanish in a fun way."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Kevin"
                position={{ color: "info", label: "Co-founder and Head Teacher" }}
                description="Born in small town 5 hours away from the capital city, I moved to this city to pursue my university studies and ended up staying because I fell in love with Oaxaca. Coming from a family of educators, teaching has always been a part of my life. I've learned to value and respect it because I believe it's one of the most beautiful ways to help someone.
                In my personal life, I have a passion for music, cinema, and languages. I strive to incorporate these three elements into my classes to make the learning process more enjoyable and meaningful.
                I'm constantly learning and open to working in any way possible. If there's an issue, I feel an urgent need to find a solution and continue aiding my students.
                This is who I am: a dedicated educator with a deep love for Oaxaca and a commitment to make the learning process more meaningful."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Enrique"
                position={{ color: "info", label: "Head Teacher" }}
                description='Writer, poet, language teacher, therapeutic tarot reader, literary text proofreader, creative writing workshop facilitator, lucid dreaming, and therapeutic tarot. Born in the city of Oaxaca, Mexico. He graduated as a language teacher in 2018 from UABJO. Since that year, he has pursued teaching, one of his greatest passions, reaching hundreds of people with individual and group classes.
                His first poetry book titled "Temporal" was published in 2019, under the Buenos Aires Poetry publishing label in Argentina.
                '
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team4}
                name="Nancy"
                position={{ color: "info", label: "Head Teacher" }}
                description="Hi, there! I'm Nancy, and I'm super excited about being part of this project. I studied a major in Foreign languages. I'm able to speak French and German. I love seeing my students accomplish their goals in learning a language. My goal as a teacher is to encourage my students to do their best in learning a new language and that they don't feel frustrated during the process. That's why I decided to become a teacher. In this program, I'll make sure you have a great time in the city and learn Spanish in an enjoyable way."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
