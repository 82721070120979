/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
//import Team from "pages/LandingPages/AboutUs/sections/Team";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
import Featuring from "./sections/Featuring";

function AboutUs() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky light={true} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h0"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our Services
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We&apos;re constantly trying to adapt for everyone, Choose the option that best suits
              you.
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { green } }) => green.main }}
              href="/pages/landing-pages/contact-us"
            >
              Schedule now
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Contact us
            </MKTypography>
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Grid item xs={2}>
                <MKTypography
                  component="a"
                  variant="h1"
                  color="white"
                  href="//api.whatsapp.com/send?phone=529516550380&text=I%20want%20to%20learn!"
                >
                  <i className="fab fa-whatsapp" />
                </MKTypography>
              </Grid>
              <Grid item xs={2}>
                <MKTypography
                  component="a"
                  variant="h1"
                  color="white"
                  onClick={() => (window.location = "mailto:cafeconespanolmx@gmail.com")}
                >
                  <Icon>mail</Icon>
                </MKTypography>
              </Grid>
              <Grid item xs={2}>
                <MKTypography
                  component="a"
                  variant="h1"
                  color="white"
                  href="https://www.instagram.com/cafeconespanolmx?igsh=ZXc5ZjBtZ28wbDV2"
                >
                  <i className="fab fa-instagram" />
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Featuring />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
