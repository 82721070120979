/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKAvatar from "components/MKAvatar";
import MKBox from "components/MKBox";
//import MyCarousel from "components/Carrousell";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import profilePicture from "assets/images/bg-principal-rosa.jpg";
//import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
//import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Images
import bgImage from "assets/images/bg-cafe-logo-principal.jpg";
import bgImage2 from "assets/images/bg-principal-rosa.jpg";

function Information() {
  return (
    <MKBox minHeight="24rem" width="100%" component="section" py={12} placeItems="center">
      <Container>
        <MKBox component="section" pt={6} px={1} mt={6}>
          <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                <MKAvatar src={profilePicture} alt="Burce Mars" size="2xl" shadow="xl" />
              </MKBox>
              <Grid container justifyContent="center" py={6}>
                <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <MKTypography variant="h3" mb={1}>
                      Explore, Learn, and Savor with Our Oaxaca Adventure Packages!
                    </MKTypography>
                  </MKBox>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={1}>
                    Start your special learning journey with us! Learn Spanish, enjoy great coffee,
                    and see famous places in Oaxaca city. Booking your class is easy and
                    stress-free. Sign up now for your own adventure with a personal tutor, and dive
                    into the mix of culture, language, and discovery. Book your class today and let
                    the fun begin!
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Grid container spacing={4} mb={4} alignItems="stretch">
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage}
                title="Double Espresso"
                description="* ✔️ 20 hours a week
                * ✔️ 2 “two-hour” sessions a day
                * ✔️ One-on-one sessions
                * ✔️ The opportunity to explore fascinating cultural landmarks in Oaxaca
                * ✔️ One non-alcoholic drink of your choice per session
                * ✔️ Digital and printed didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$8000 MXN > $7600 MXN (LIMITED-TIME DISCOUNT)",
                }}
                alignment="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage2}
                title="Espresso"
                description="* ✔️ 10 hours a week
                * ✔️ 1 “two-hour” session a day
                * ✔️ One-on-one sessions
                * ✔️ The opportunity to explore fascinating cultural landmarks in Oaxaca
                * ✔️ One non-alcoholic drink of your choice per session
                * ✔️ Digital and printed didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$4000 MXN > $3800 MXN (LIMITED-TIME DISCOUNT)",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage}
                title="Capuccino"
                description="* ✔️ 15 hours a week
                * ✔️ 1 “three-hour” session a day
                * ✔️ One-on-one sessions
                * ✔️ The opportunity to explore fascinating cultural landmarks in oaxaca
                * ✔️ One non-alcoholic drink of your choice per session
                * ✔️ Digital and printed didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$5600 MXN > $5400 MXN (LIMITED-TIME DISCOUNT)",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage2}
                title="Decaf"
                description="* ✔️ 10 hours a week
                * ✔️ 1 “two-hour” session a day
                * ✔️ One-on-one sessions
                * ✔️ Digital and printed didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$3600 MXN",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage}
                title="De Olla"
                description="* ✔️ For two people
                * ✔️ 15 hours a week
                * ✔️ 1 “three-hour” session a day
                * ✔️ A private tutor just for the both of you
                * ✔️ The opportunity to explore fascinating cultural landmarks in oaxaca
                * ✔️ Two non-alcoholic drink of your choice per session
                * ✔️ Digital and printed didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$8400 MXN",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CenteredBlogCard
                image={bgImage2}
                title="To-go coffee"
                description="* ✔️ Online
                * ✔️ 10-hour pack
                * ✔️ 1-hour live sessions
                * ✔️ One-on-one sessions
                * ✔️ Flexibility for the teacher and you to work at your own pace
                * ✔️ Digital didactic resources tailored to our own curriculum
                "
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  color: "info",
                  label: "$3200 MXN",
                }}
              />
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Information;
