// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Café con Español",
    image: logoCT,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "company",
      items: [
        {
          name: "about us",
          route: "/pages/more-about-us",
        },
      ],
    },
    {
      name: "help & support",
      items: [{ name: "contact us", route: "/pages/landing-pages/contact-us" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Café con Español by{" "}
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        McNum
      </MKTypography>
      .
    </MKTypography>
  ),
};
