/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import paypal from "assets/images/shapes/paypal.svg";
import cash from "assets/images/shapes/cash-payment-6401.svg";

function Featuring() {
  return (
    <MKBox component="section" pt={4} pb={4}>
      <Container>
        <MKTypography variant="h3" color="info" textGradient textAlign="center">
          {"Payment methods"}
        </MKTypography>
        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <MKBox component="img" src={paypal} alt="paypal" width="100%" opacity={0.8} />
          </Grid>
          <Grid container item alignItems="center" xs={12} md={4} lg={4}>
            <Grid item xs={6} md={3} lg={3}>
              <MKBox component="img" src={cash} alt="cash" width="100%" opacity={0.8} />
            </Grid>
            <Grid item xs={6} md="auto" lg="auto">
              <MKTypography variant="h1" color="info" textAlign="left" mt={1}>
                {"Cash"}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
