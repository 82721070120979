/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/bg-cafe-logo-principal.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Café con Español</MKTypography>
                <MKButton
                  variant="outlined"
                  color="info"
                  size="small"
                  href="https://www.instagram.com/cafeconespanolmx"
                >
                  Follow
                </MKButton>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <MKTypography variant="body1" fontWeight="light" color="text" mb={1}>
                  We are a team with extensive experience in the field of education. As a group of
                  Oaxacans concerned about the cultural shifts brought about by tourism in our city,
                  we seek to innovate the approach and structure of classical Spanish teaching. We
                  have developed a curriculum based on the needs of any tourist visiting a
                  Spanish-speaking country.
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={1}>
                  We hold the belief that a language is intricately linked with the culture of its
                  native country. Therefore, we are interested in ensuring that both elements are
                  present in each of our lessons, in order to provide our students with a more
                  enriching and meaningful learning experience.
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={1}>
                  We invite all our students to collaborate and interact with Oaxacan citizens to
                  generate a healthier and more beneficial tourism for all involved parts.
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  More about us <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
