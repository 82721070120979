import React from "react";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.css";
import ImageCard from "examples/Cards/BlogCards/ImageCard";
import MKTypography from "components/MKTypography";
import { onlineImages } from "./config.js";

function OnlineCarousel() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <MKTypography variant="h2" textAlign="center" mb="20px">
        {"Online Classes"}
      </MKTypography>
      <Slider {...settings}>
        {onlineImages.map((image, index) => (
          <div key={index}>
            <ImageCard
              image={image}
              title={`Student ${index + 1}`}
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "Schedule Here",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OnlineCarousel;
