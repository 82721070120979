/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function DesignBlocks() {
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="ARE YOU READY TO SPEAK SPANISH AS A LOCAL?"
            container
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            A class in real places with real people
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Welcome to our Spanish Language Academy! Immerse yourself in the rich culture and
            heritage of Oaxaca while learning the beautiful Spanish language. Our immersive classes
            and unique experiences will take you on a linguistic adventure like no other.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
